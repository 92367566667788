<template>
    <div>
      <v-card v-if="documentData" class="pa-2">
        <v-card-title class="subheading font-weight-bold info--text">
          <span>{{ documentData.title }}</span>
        </v-card-title>
        <v-card-subtitle
            class="d-flex"
        >
          <span>{{ documentData.description }}</span>
          <v-spacer />
          <v-btn
              class="mt-3"
              link
              :to="{name:'userDocuments',params:{id:documentData.id}}"
          >
            TORNA ALLA LISTA DOCUMENTI
          </v-btn>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <div
              class="d-flex justify-end"
          >
            <v-btn
                color="primary"
                @click="isShow.upload = true"
            >
              <v-icon

              >
                mdi-upload
              </v-icon>
              CARICA FILE
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="documentData.documentFiles_count > 0"
                color="primary"
                @click="downloadAllWithAxios(documentData.title+'.zip')"
                :loading="loadingAll"
            >
              <v-icon>
                mdi-download
              </v-icon>
              Scarica intero documento
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

        <v-row
            v-if="documentData.documentFiles_count > 0"
        >
            <v-col cols="3" v-for="(file,idx) in documentData.user_document_files" :key="idx">
              <file-card
                :fileObj="file"
                :downloadUrl = downloadUrl(file.id)
                @deleteFile="isShow.delete=true"
              />
            </v-col>
        </v-row>
      <upload-file
        :is-show="isShow.upload"
        @close="isShow.upload=false"
        :upload-url="uploadUrl"
        @reload-data="reloadData"
      />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import UploadFile from "@/components/modals/upload-file";
import FileCard from "@/components/cards/file-card";

export default {
    components: {
      FileCard,
      UploadFile
    },
    data() {
        return {
            isShow: {
              upload: false
            },
            documentData: {},
            loading: {0:false},
            loadingAll : false,
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Documenti condivisi',
                    to: '/user-documents/'+this.$route.params.uid,
                },
                {
                    text: 'Documento',
                    to: '/user-document/'+this.$route.params.uid+'/documents/'+this.$route.params.did
                }
            ],
        }
    },
    mounted() {
        this.getData()
        this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
    },
    computed: {
        ...mapGetters(['loggedUser','userDocumentsList']),
        uploadUrl() {
            return this.$apiUrl+'user-documents/'+this.loggedUser.id+'/documents/'+this.documentData.id
        },
    },
    methods: {
        async getData() {
            let document_id = parseInt(this.$route.params.did)
            this.documentData = await this.userDocumentsList.find(item => item.id === document_id);
        },
        async reloadData() {
          await this.$store.dispatch('getUserDocuments')
          await this.getData()
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        downloadWithAxios(fid, title, idx) {
            let url = this.$apiUrl+'user-documents/' +this.$route.params.uid + '/documents/' + this.$route.params.did + '/files/' + fid
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    this.forceFileDownload(response, title)
                    this.setLoading(idx)
                })
                .catch(() => console.log('error occured'))
        },
        downloadAllWithAxios(title) {
            this.loadingAll = true
            let url = this.$apiUrl+'user-documents/'+ this.$route.params.uid + '/documents/' + this.$route.params.did + '/download/all'
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    this.forceFileDownload(response, title)
                    this.loadingAll = false
                })
                .catch(() => {
                    console.log('error occured')
                    this.loadingAll = false
                })

        },
        setLoading(idx){
            if(this.loading[idx] === undefined){
                this.$set(this.loading,idx,true);
            }else{
                this.loading[idx] = !this.loading[idx];
            }

        },
        formatWeight(size){
            let finalSize = size/1000
            // mega
            if(finalSize > 1000){
                finalSize = finalSize/1000
                return finalSize.toFixed(2) + ' MB'
            }else{
                return finalSize.toFixed(0) + ' KB'
            }
        },
        downloadUrl(file_id) {
          return this.$apiUrl+'user-documents/' +this.$route.params.uid + '/documents/' + this.$route.params.did + '/files/' + file_id
        },
    }
}
</script>

<style scoped>

</style>
