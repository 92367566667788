import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.documentData)?_c(VCard,{staticClass:"pa-2"},[_c(VCardTitle,{staticClass:"subheading font-weight-bold info--text"},[_c('span',[_vm._v(_vm._s(_vm.documentData.title))])]),_c(VCardSubtitle,{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.documentData.description))]),_c(VSpacer),_c(VBtn,{staticClass:"mt-3",attrs:{"link":"","to":{name:'userDocuments',params:{id:_vm.documentData.id}}}},[_vm._v(" TORNA ALLA LISTA DOCUMENTI ")])],1),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.isShow.upload = true}}},[_c(VIcon,[_vm._v(" mdi-upload ")]),_vm._v(" CARICA FILE ")],1),_c(VSpacer),(_vm.documentData.documentFiles_count > 0)?_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingAll},on:{"click":function($event){return _vm.downloadAllWithAxios(_vm.documentData.title+'.zip')}}},[_c(VIcon,[_vm._v(" mdi-download ")]),_vm._v(" Scarica intero documento ")],1):_vm._e()],1)])],1):_vm._e(),(_vm.documentData.documentFiles_count > 0)?_c(VRow,_vm._l((_vm.documentData.user_document_files),function(file,idx){return _c(VCol,{key:idx,attrs:{"cols":"3"}},[_c('file-card',{attrs:{"fileObj":file,"downloadUrl":_vm.downloadUrl(file.id)},on:{"deleteFile":function($event){_vm.isShow.delete=true}}})],1)}),1):_vm._e(),_c('upload-file',{attrs:{"is-show":_vm.isShow.upload,"upload-url":_vm.uploadUrl},on:{"close":function($event){_vm.isShow.upload=false},"reload-data":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }